import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import HeroBanner from '../components/hero-banner'

const IndexPage = () => {
  return (
    <Layout pageTitle="Home" headerOverlay={true} showPageBanner={false}>
      <Helmet>
        <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "GeneralContractor",
            "name": "S&A Innovations",
            "url": "https://sainnovations.net",
            "telephone": "(508) 667-3686",
            "email": "sainnovations@hotmail.com",
            "logo": "https://sainnovations.net/sa-logo.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "294 Little Alum Rd",
              "addressLocality": "Brimfield",
              "addressRegion": "MA",
              "postalCode": "01010",
              "addressCountry": "US"
            },
            "sameAs": [
              "https://www.instagram.com/s_and_a_innovations/"
            ]
          }
        `}
        </script>
      </Helmet>
      <HeroBanner
        imageSource='stock-photos/back-deck.jpg'
        imageAlt=''
      >
        <h1>Frame to finish construction</h1>
        <p className="text-lg md:text-xl xl:text-2xl">Servicing central Massachusetts and the surrounding areas</p>
      </HeroBanner>
    </Layout>
  )
}

export default IndexPage