import * as React from 'react'
import Image from './image'

const HeroBanner = ({ imageSource, imageAlt, children }) => {
  return (
    <div className="relative flex items-center h-screen overflow-hidden">
      <Image
        src={imageSource}
        alt={imageAlt}
        className="fixed top-0 brightness-50 z-0 h-full w-full object-cover object-center"
      />
      <div className="container relative text-white text-center py-24">
        {children}
      </div>
    </div>
  )
}

export default HeroBanner