import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Image = ({ src, alt, className }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(sort: { fields: name }, filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
        nodes {
          id
          name
          relativePath
          childImageSharp {
            gatsbyImageData(
              width: 1600
            )
          }
        }
      }
    }
  `);

  console.log(data)

  const imageNode = data.allFile.nodes.find(node => src === node.relativePath);
  if (!imageNode) return null;

  const image = getImage(imageNode);

  return <GatsbyImage image={image} alt={alt} className={className} />;
};

export default Image;